<template >
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
        <h3 style="margin-top: 12px" class="title-headd">CM Dashboard</h3>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="10">
      <vs-row vs-w="12" vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="3"
        >
        <date-range-picker
          ref="picker"
          :opens="'center'"
          :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
          :minDate="null"
          :maxDate="null"
          :singleDatePicker="false"
          :timePicker="false"
          style="width: 240px; "
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          v-model="dateRange"
          class="daterange-font"
        >
        </date-range-picker>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="2.5"
        >
          <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
              <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                City
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                <treeselect v-model="selected_city" :multiple="true" :options="tree_City" />
              </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="2.5"
        >
        <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
              <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                GM
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                <treeselect v-model="selected_gm_cc" slot="" :multiple="true" :options="city_with_gm" />
              </vs-dropdown-menu>
        </vs-dropdown>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="2.5"
        >
        <vs-dropdown vs-custom-content vs-trigger-click class="dropp-down">
              <a class="a-icon a-iconn" href.prevent @click="showTreeBox">
                Spoc
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="dropdown-login dropdown-cm customDropDown">
                <treeselect v-model="selected_spoc" :multiple="true" :options="city_with_cm" />
              </vs-dropdown-menu>
        </vs-dropdown>
        
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="1.2"
        >
        <vs-button
          color="dark"
          icon="search"
          type="gradient"
          @click="searchByDate"
        >
        </vs-button>
        &nbsp;
        <vs-button
          color="dark"
          type="gradient"
          icon="close"
          @click="refreshData"
          title="Refresh"
        ></vs-button>
        </vs-col>
      </vs-row>
      </vs-col>
    </vs-row>
    <vs-row style="margin-top: 2%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
        <b>Search Criteria:</b>
      </vs-col>
      <vs-col style="overflow-y: scroll" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
        <div v-for="(chip, index) in searchCriteria" :key="index">
          <vs-chip v-if="chip !== 'divider'" closable @click="removechip(chip)" color="#0044ba">
            <b>{{ chip }}</b>
          </vs-chip>
          <div v-else>
            <div class="vl"></div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <div style="margin-top: 15px">
      <!-- Top spocs -->

      <top-spocs :top_spocs_summarys="top_spocs_summarys"></top-spocs>
      <!-- All India Rank Summary -->
      <all-india-rank-summary :spoc="this.selected_spoc" :all_india_rank_summary="all_india_rank_summary"
        v-if="Object.keys(all_india_rank_summary).length > 1"></all-india-rank-summary>
      <!-- Overall Trends -->
      <overall-trends :overall_trends="overall_trends" v-if="overall_trends.length > 0"></overall-trends>

      <!-- Key Metrics -->
      <div v-if="
          key_metrics.length > 0 || Object.keys(visit_calls_object).length > 0
      ">
        <vs-card>
          <h3 class="card-header">KEY METRICS</h3>
          <vs-row vs-justify="center" vs-align="center" vs-type="flex" vs-w="12" style="margin-top: 2%">
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-lg="2" vs-sm="6" vs-xs="5" style="margin-right:8px;"
              v-for="(key_metric, index) in key_metrics" :key="index">
              <key-metric-card :key_metric="key_metric" @total-with-city-enrollment="getCityEnrollment">
              </key-metric-card>
            </vs-col>
            <vs-col vs-justify="space-between" vs-align="center" vs-type="flex" vs-lg="2" vs-sm="6" vs-xs="5"
              v-for="(visit_calls, key) in visit_calls_object" :key="visit_calls.visits_key">
              <visitors-card :visits_key="key" :visits_value="visit_calls"
                @show-calls-city-summary="getCallsCitySummary"></visitors-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Enrollments Course Wise -->
      <div>
        <vs-card v-if="course_wises.length > 0">
          <h3 class="card-header">ENROLLMENT COURSE-WISE</h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(course_wise, index) in course_wises" :key="index">
              <course-wise-card :course_wise="course_wise" @show-city-enrollment="getCityEnrollment">
              </course-wise-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Eligibility  -->
      <div v-if="Object.keys(eligibility_obj).length > 1">
        <vs-card>
          <h3 class="card-header">ELIGIBILITY</h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(eligibility, key, index) in eligibility_obj" :key="index">
              <eligibility-card :eligibility_key="key" :eligibility_value="eligibility"
                @show-eligibility-city-summary="getEligibilityCitySummary"></eligibility-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Referrals  -->
      <div v-if="Object.keys(referrals_obj).length > 1">
        <vs-card>
          <h3 class="card-header">REFERRALS</h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(referral, key, index) in referrals_obj" :key="index">
              <referrals-card :referral_key="key" :referral_value="referral"
                @show-referrals-city-summary="getReferralsCitySummary"></referrals-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <!-- Roadmap Webinar -->
      <div v-if=" Object.keys(road_map_overall_counts).length > 1">
        <vs-card>
          <h3 class="card-header">ROADMAP WEBINAR </h3>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="space-around" vs-lg="2" vs-sm="4" vs-xs="5"
              v-for="(road_map_overall_count, key, index) in road_map_overall_counts" :key="index">
              <roadmap-webinar :road_map_key="key" :road_map_value="road_map_overall_count"
                @show-road-map-type="getRoadmapCitySummary"></roadmap-webinar>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <!--Net Enquiry -->
      <div v-if="overall_sources.length > 1">
        <vs-card>
          <div class="card-header">
            <vs-row vs-w="12">
              <vs-col vs-w="3">
                <h3 class="card-header">NET ENQUIRIES<span v-if='selected_source !== "" && selected_source !== null'> :
                    {{ selected_source }}</span></h3>
              </vs-col>
              <vs-col vs-offset="7" vs-w="2">
                <v-select clearable searchable class="selectSource" placeholder="Source" :options="overall_sources"
                  v-model="selected_source">
                </v-select>

              </vs-col>
            </vs-row>
          </div>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" v-if='selected_source == "" || selected_source == null'>
            <h4 style="margin:1%">Please Select Source</h4>
          </vs-row>
          <vs-row vs-justify="center" vs-type="flex" vs-w="12" style="margin-top: 3%">
            <vs-col vs-w="2" v-for="(net_enquiry, key, index) in net_enquirys[0]" :key="index">
              <net-enquiry-card :net_enquiry="net_enquiry" :net_enquiry_key="key" :source="selected_source"
                @total-with-city-netenquiry="getNetEnquirysCitySummary">
              </net-enquiry-card>
            </vs-col>
          </vs-row>
        </vs-card>
      </div>
      <br />
      <!-- Leads Summary-->
      <leads-summary :leads_summary="leads_summary" :select_city="city_by_search" v-if="leads_summary.length > 0"
        @refresh-city-data="refresh-city-data"></leads-summary>

      <!-- Enrollments Popup -->
      <enrollments-popup :enrollments_popup="enrollments_popup" :city_wises_enrollments="city_wises_enrollments" :team="teamm"
        :date_props="date_props" @close-enrollments-popup="enrollments_popup = false; this.selected_course = ''">
      </enrollments-popup>

      <!-- Connected Call Popup -->
      <ConnectedCallsPopup :calls_city_summary_popup="calls_city_summary_popup" :calls_city_wises="calls_city_wises" :team="teamm"
        :date_props="date_props" @close-connected-call-popup="calls_city_summary_popup = false"></ConnectedCallsPopup>
      <!-- Visit Count Popup -->
      <visit-count-popup :visits_city_summary_popup="visits_city_summary_popup" :visits_city_wises="visits_city_wises" :team="teamm"
        :date_props="date_props" @close-visit-count-popup="visits_city_summary_popup = false"></visit-count-popup>
      <!-- Eligibility Popup -->
      <eligibility-enrollments-popup :eligibility_city_wises="eligibility_city_wises"
        :eligibility_city_summary_popup="eligibility_city_summary_popup" :date_props="date_props"
        @close-eligibility-popup="eligibility_city_summary_popup = false">
      </eligibility-enrollments-popup>
      <!-- Referral city wise popup -->
      <referral-city-wise-popup :referrals_city_wises="referrals_city_wises"
        :referral_city_summary_popup="referral_city_summary_popup" :date_props="date_props"
        @close-referral-city-popup="referral_city_summary_popup = false">
      </referral-city-wise-popup>
      <!-- Road Map Webinar City -->
      <roadmap-webinar-city-wise-popup :roadmap_city_summary_popup="roadmap_city_summary_popup"
        :roadmap_city_wises="roadmap_city_wises"
        @close-roadmap-city-popup="roadmap_city_summary_popup = false" :date_props="date_props"></roadmap-webinar-city-wise-popup>
      <!-- net enquiry city wise popup -->
      <net-enquiry-popup :net_enquiry_summary_popup="net_enquiry_summary_popup"
        :netenquiry_city_wises="netenquiry_city_wises" :date_props="date_props"
        @close-net-enquiry-popup="net_enquiry_summary_popup = false">
      </net-enquiry-popup>


    </div>
  </div>
</template>
<script>
import axios from "axios";
import ApiServices from "../../ApiServices";
import constants from "../../../constants.json";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import KeyMetricCard from "../../components/CmDashboard/keyMetricCard.vue";
import CourseWiseCard from "../../components/CmDashboard/CourseWiseCard.vue";
import CityWiseCard from "../../components/CmDashboard/CityWiseCard.vue";
import EligibilityCard from "../../components/CmDashboard/EligibilityCard.vue";
import RoadmapWebinar from "../../components/CmDashboard/RoadmapWebinarCard.vue";
import ReferralsCard from "../../components/CmDashboard/ReferralsCard.vue";
import VisitorsCard from "../../components/CmDashboard/VisitorsCard.vue";
import ConectedCalls from "../../components/CmDashboard/ConectedCalls.vue";
import RoadmapWebinarCityWisePopup from "../../components/CmDashboard/RoadmapWebinarCityWisePopup.vue"
import VisitCalls from "../../components/CmDashboard/VisitCalls.vue";
import EligibilityCityWise from "../../components/CmDashboard/EligibilityCityWise.vue";
import ReferralsCityWise from "../../components/CmDashboard/ReferralsCityWise.vue";
import OverallTrends from "../../components/CmDashboard/OverallTrends.vue";
import AllIndiaRankSummary from "../../components/CmDashboard/AllIndiaRankSummary.vue";
import TopSpocs from "../../components/CmDashboard/TopSpocsSummary.vue"
import EnrollmentsPopup from "../../components/CmDashboard/EnrollmentsPopup.vue";
import ConnectedCallsPopup from "../../components/CmDashboard/ConnectedCallsPopup.vue";
import VisitCountPopup from "../../components/CmDashboard/VisitCountPopup.vue";
import EligibilityEnrollmentsPopup from "../../components/CmDashboard/EligibilityEnrollmentsPopup.vue";
import ReferralCityWisePopup from "../../components/CmDashboard/ReferralCityWisePopup.vue";
import NetEnquiryCard from "../../components/CmDashboard/NetEnquiryCard.vue";
import NetEnquiryPopup from "../../components/CmDashboard/NetEnquiryPopup.vue";
import LeadsSummary from "../../components/CmDashboard/LeadsSummary.vue";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    Treeselect,
    KeyMetricCard,
    CourseWiseCard,
    'top-spocs': TopSpocs,
    "v-select": vSelect,
    CityWiseCard,
    DateRangePicker,
    RoadmapWebinarCityWisePopup,
    EligibilityCard,
    ReferralsCard,
    VisitorsCard,
    ConectedCalls,
    VisitCalls,
    EligibilityCityWise,
    ReferralsCityWise,
    EnrollmentsPopup,
    OverallTrends,
    AllIndiaRankSummary,
    ConnectedCallsPopup,
    VisitCountPopup,
    EligibilityEnrollmentsPopup,
    ReferralCityWisePopup,
    NetEnquiryCard,
    NetEnquiryPopup,
    LeadsSummary,
    RoadmapWebinar,
  },
  mounted() {
    let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    this.date_range_for_spocs = { 'startDate': startDate, 'endDate': endDate, };
    this.getEnrollmentData();
    this.getVisitsData();
    this.getEligibilityData();
    this.getReferralsData();
    this.getRoadmapOverallCount();
    this.getNetEnquiryData("Google");
    this.getAllGmWithSpoc();
    this.getOverallTrendsData();
    this.getAllIndiaRankSummary();
    this.getOverallLeads();
    this.getSpocsBasedOnCC();
    this.getTopSpocs();
    this.gm_names = [];
  },
  data() {
    return {
      teamm:"CM",
      searchCriteria: [],
      selected_gms : [],
      date_range_for_spocs: {},
      date_props: [],
      cities: [],
      selected_city: [],
      selected_city_classification: '',
      open: false,
      dateRange: {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        endDate: new Date(),
      },
      all_gm_with_spoc: [],
      net_enquirys: [],
      netenquiry_city_wises: [],
      key_metrics: [],
      course_wises: [],
      city_wises_enrollments: [],
      selected_course: "",
      selected_spoc: [],
      selected_gm: "",
      selected_source: "Google",
      spoc_names: [],
      gm_names: [],
      overall_sources: [],
      gm_selecter: true,
      visit_calls_object: {},
      calls_city_wises: [],
      eligibility_city_wises: [],
      referrals_city_wises: [],
      visits_city_wises: [],
      eligibility_obj: {},
      referrals_obj: {},
      road_map_overall_counts:{},
      roadmap_city_wises: [],
      net_enquiry_summary_popup: false,
      eligibility_city_summary_popup: false,
      referral_city_summary_popup: false,
      roadmap_city_summary_popup: false,
      calls_city_summary_popup: false,
      visits_city_summary_popup: false,
      enrollments_popup: false,
      overall_trends: [],
      all_india_rank_summary: {},
      gm_spocs: {},
      eligibility_city_popup: false,
      visits_city_popup: false,
      leads_summary: [],
      all_net_enquiry_data: [],
      spoc_data: [],
      city_classifications: [],
      city_with_gm: [],
      city_with_cm: [],
      selected_gm_cc: [],
      city_by_search: '',
      top_spocs_summarys: [],
      tree_City: [],
    };
  },
  watch: {
    selected_source(val) {
      // if (selected_source !== "" && selected_source !== null) {
      //   this.getNetEnquiryData(selected_source);
      //   return " : " + selected_source
      // }else{
      //   this.net_enquirys = []
      // }
      console.log("selected-source", val, this.all_net_enquiry_data)
      this.net_enquirys = [];
      for (let i = 0; i < this.all_net_enquiry_data.length; i++) {
        const element = this.all_net_enquiry_data[i];
        if (element.source == val) {
          let obj = {
            "netenquiry_enrollment_conversion": element.netenquiry_enrollment_conversion,
            "new_enquiry_enrollments": element.new_enquiry_enrollments,
            "new_netenquiries": element.new_netenquiries,
            "new_netenquiry_conversion": element.new_netenquiry_conversion,
            "overall_netenquiry_enrollments": element.overall_netenquiry_enrollments,
          }
          // delete element["source"]
          // delete element["total_enrollments"]
          this.net_enquirys.push(obj)
          // console.log("this.net",this.net_enquirys)
        }
      }
    },
    selected_city(val) {
      // console.log("selected_city",val,)
      this.selected_gm_cc = [];
      this.selected_spoc = [];
      this.city_with_gm = [];
      this.selected_gms = [];
      const city_wise_gm = []
      this.city_classifications = []
      if(this.selected_city.length != 0){
        for (let i = 0; i < this.spoc_data.length; i++) {
          const element = this.spoc_data[i];
          for (let j = 0; j < val.length; j++) {
            const selectCity = val[j];
            if (element.city == selectCity) {
              this.city_classifications.push(element);
          this.city_with_gm = [];
            }
          }
        }
          city_wise_gm.push(
            ...new Set(
              this.city_classifications.map(
                (item) => `${item.city_classification}-${item.GM}`
              )
            )
          )
          for (let i = 0; i < city_wise_gm.length; i++) {
            const data = city_wise_gm[i];
            const obj = {
                id: data,
                label: data,
              };
            this.city_with_gm.push(obj)
          }
      
      }
      this.mergeAllSearch();
      // console.log("city_classification",this.city_with_gm.length)
    },
    selected_gm_cc(val){
      this.city_with_cm =[];
      this.selected_spoc =[];
      let gms = []
      var cc = null;
      var gm_name = null;
      if(this.selected_gm_cc.length !=0){
        for (let i = 0; i < this.spoc_data.length; i++) {
          const element = this.spoc_data[i];
          for (let index = 0; index < val.length; index++) {
            const details = val[index];
            let gm_split = details.split("-");
            cc =  gm_split[0].trim();
            gm_name = gm_split[1].trim();
            if(element.city_classification == cc && element.GM == gm_name){
              gms.push(gm_name)
              const obj = {
                id: element.spoc_name,
                label: element.spoc_name,
              };
              this.city_with_cm.push(obj)
            }
          }
        }
        this.removeDuplicates(gms)
      }
      this.mergeAllSearch();
    },
    selected_spoc(selected_spoc) {
      if (selected_spoc == [] || selected_spoc == null) {
        this.selected_spoc = []
        this.all_india_rank_summary = {};
      }
      this.mergeAllSearch();
      // console.log("selected_spoc spoc name", this.selected_spoc);
    },
    // selected_gm: function (gm) {
    //   console.log("selected gm", gm);
    //   this.spoc_name = [];
    //   if (gm == "" || gm == null) {
    //     this.selected_spoc = "";
    //     this.selected_gm = "";
    //   }
    //   this.spoc_names = this.gm_spocs[gm];
    //   console.log("spoc name", this.spoc_names);
    // },
    // calls_city_summary_popup: function (val) {
    //   console.log("calls_city_summary_popup---", val);
    //   console.log(
    //     "calls_city_summary_popup enrollmenrt---",
    //     this.enrollments_popup
    //   );
    // },
    // enrollments_popup: function (val) {
    //   console.log("enrollments_popup---", val);
    //   console.log(
    //     "enrollments_popup calls_city_summary_popup---",
    //     this.calls_city_summary_popup
    //   );
    // },
  },
  methods: {
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");

      this.selected_city.forEach(element => {
          this.searchCriteria.push(element);  
      });

      this.selected_gm_cc.forEach(element => {
          this.searchCriteria.push(element);  
      });

      this.selected_spoc.forEach(element => {
          this.searchCriteria.push(element);  
      });
      
    },
    removechip(chip) {
      let chipPlacement = "";
      
      this.selected_city.forEach(element => {
        if (element === chip) {
          chipPlacement = "city";
        }
      });
      this.selected_gm_cc.forEach(element => {
        if (element === chip) {
          chipPlacement = "gm";
        }
      });
      this.selected_spoc.forEach(element => {
        if (element === chip) {
          chipPlacement = "spoc";
        }
      });
      switch (chipPlacement) {
        case "city":
          this.selected_city.splice(
            this.selected_city.indexOf(chip),
            1
        );
          break;
        case "gm":
          this.selected_gm_cc.splice(
            this.selected_gm_cc.indexOf(chip),
            1
        );
        break;
        case "spoc":
          this.selected_spoc.splice(
            this.selected_spoc.indexOf(chip),
            1
        );
      }
    },
    showTreeBox() {
      return
    },
    removeDuplicates(arr) {
        this.selected_gms = arr.filter((item, index) => arr.indexOf(item) === index);
    },
    getTopSpocs() {
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      ApiServices.getTopSpocs(startDate, endDate,this.teamm,this.selected_city.toString()).then((response) => {
          // console.log("getTopSpocs", response.data)
        if (typeof response.data == 'object') {
          this.top_spocs_summarys = response.data
          console.log("getTopSpoc", this.top_spocs_summarys)
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getSpocsBasedOnCC() {
      let url = `https://milesdashboards.2x2.ninja/api/getSpocsBasedonCC`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.spoc_data = response.data
          // console.log("getSpocsBasedonCC", response.data)
          this.cities = [
            ...new Set(response.data.map((item) => item.city)),
        ].sort();
          this.getTreeCity();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getTreeCity() {
      if (this.cities.length != 0) {
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        for (let i = 0; i < this.cities.length; i++) {
          const element = this.cities[i];
          const obj = {
            id: element,
            label: element,
          };
          if(city_options.includes(element)){
            this.tree_City.push(obj);
          }
        }
        console.log("treeDetails", this.tree_City);
      }
    },
    getNetEnquiryData(source) {
      // this.course_wises = [];
      this.net_enquirys = [];
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      let obj = { spoc: this.selected_spoc, gm: this.selected_gms.toString(), city: this.selected_city.toString() };
      ApiServices.getNetEnquiryData(startDate, endDate, obj).then((response) => {
          let enrollment = response.data;
        this.all_net_enquiry_data = response.data
          // console.log("neti", response.data);
          let check_if_array = Array.isArray(enrollment);
          if (check_if_array) {
          this.overall_sources = []
            for (let i = 0; i < enrollment.length; i++) {
              this.overall_sources.push(enrollment[i].source);
              if (enrollment[i].source == source) {
              var element = enrollment[i]
                let all_net_data = {
                "netenquiry_enrollment_conversion": element.netenquiry_enrollment_conversion,
                "new_enquiry_enrollments": element.new_enquiry_enrollments,
                "new_netenquiries": element.new_netenquiries,
                "new_netenquiry_conversion": element.new_netenquiry_conversion,
                "overall_netenquiry_enrollments": element.overall_netenquiry_enrollments,
              }
                // delete element["source"]
                // delete element["total_enrollments"]
              this.net_enquirys.push(all_net_data)
              }
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getEnrollmentData() {
      this.course_wises = [];
      this.key_metrics = [];
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        city: this.selected_city.toString(),
        spoc: this.selected_spoc.toString(),
        gm: this.selected_gms.toString(),
        team: this.teamm
      };
      // console.log("start_date", startDate);
      // console.log("end_date", endDate);
      // console.log("selected_spoc", this.selected_spoc);
      // console.log("selected_gm", this.selected_gm);
      // let url = `${constants.MILES_DASHBOARDS}getOverallEnrollmentsCount?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // // let url = `http://milesapi.bulbot.com/api/performance-dashboard/enrollments-overall-count/?end-date=${endDate}&start-date=${startDate}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getEnrollmentData(startDate, endDate, obj)
        .then((response) => {
          let enrollment = response.data;
          // console.log("enrollment", enrollment);
          let check_if_array = Array.isArray(enrollment);
          if (check_if_array) {
            for (let i = 0; i < enrollment.length; i++) {
              const element = enrollment[i];
              if (element.course == "Total enrollments") {
                this.key_metrics.push(element);
              } else if (element.course != "Total enrollments") {
                this.course_wises.push(element);
              } else {
                this.key_metrics = [];
                this.course_wises = [];
              }
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getCityEnrollment(course) {
      // console.log("course--", course);
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.netenquiry_city_wises = [];
      if (course == null) {
        this.selected_course = "";
      } else {
        this.selected_course = course;
      }
      // console.log("course2--", course);
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        city: this.selected_city.toString(),
        spoc: this.selected_spoc,
        gm: this.selected_gms.toString(),
        course: this.selected_course,
        team: this.teamm
      };
      if (this.selected_course != "") {
        this.date_props = {
          card_header:
            startDate + " - " + endDate + " | " + this.selected_course,
          city: this.selected_city.toString(),
          gm: this.selected_gms.toString(),
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: this.selected_course
        };
        // console.log("this.date_props course not empty", this.date_props)
      } else {
        this.date_props = {
          city: this.selected_city.toString(),
          card_header: startDate + " - " + endDate,
          gm: this.selected_gms.toString(),
          spoc: this.selected_spoc,
          startDate: startDate,
          endDate: endDate,
          course: ""
        };
        // console.log("this.date_props course empty", this.date_props)
      }
      ApiServices.getCityEnrollment(startDate, endDate, obj)
        .then((response) => {
          // console.log("Find Table Data", response.data);
          this.city_wises_enrollments = response.data;
          this.enrollments_popup = true;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getAllGmWithSpoc() {
      this.gm_names = [];
      let url = `${constants.MILES_DASHBOARDS}gmSpoc`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log("gmSpoc", response);
          this.gm_spocs = response.data;
          this.gm_names = Object.keys(this.gm_spocs);
          // console.log("gm_names--", this.gm_names);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    searchByDate() {
      this.all_india_rank_summary = {};
      this.overall_sources = [];
      this.leads_summary = [];
      this.overall_trends = [];
      this.key_metrics = [];
      this.course_wises = [];
      this.city_wises_enrollments = [];
      this.visit_calls_object = {};
      this.eligibility_obj = {};
      this.referrals_obj = {};
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.road_map_overall_counts =[];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.netenquiry_city_wises = [];
      this.city_by_search = this.selected_city
      this.getTopSpocs();
      this.getEnrollmentData();
      this.getVisitsData();
      this.getEligibilityData();
      this.getRoadmapOverallCount();
      this.getReferralsData();
      this.getOverallTrendsData();
      this.getAllIndiaRankSummary();
      this.getNetEnquiryData("Google");
      this.getOverallLeads();
    },
    getVisitsData() {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc.toString(),
        gm: this.selected_gms.toString(),
        city: this.selected_city.toString(),
        team:this.teamm
      };
      console.log("object--",obj,startDate,endDate);
      // console.log("start_date", startDate);
      // console.log("end_date", endDate);
      // console.log("selected_spoc", this.selected_spoc);
      // console.log("selected_gm", this.selected_gm);
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-visits-calls/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallVisitsCalls?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getVisitsData(startDate, endDate, obj)
        .then((response) => {
          let visits = response.data;
          // console.log("visits", visits);
          let check_if_array = Array.isArray(visits);
          let visit_call = {};
          if (check_if_array) {
            for (let i = 0; i < visits.length; i++) {
              visit_call = visits[0];
              // this.key_metrics.push(element);
              console.log("key metrics object", visit_call);
            }
            this.visit_calls_object = {
              connected_calls: {
                count: visit_call["connected_calls"],
                utilization: visit_call["call_utilization"],
              },
              visit_count: {
                count: visit_call["visit_count"],
                utilization: visit_call["visit_utilization"],
              },
            };
            // console.log("visit_calls_object", this.visit_calls_object);
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getCallsCitySummary(val) {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        city: this.selected_city.toString(),
        spoc: this.selected_spoc,
        gm: this.selected_gms.toString(),
        team:this.teamm
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        city: this.selected_city.toString(),
        gm: this.selected_gms.toString(),
        spoc: this.selected_spoc,
      };
      if (val == "connected_calls") {
        this.city_wises_enrollments = [];
        this.visits_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/calls-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getCallsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm_name}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getCallsCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.calls_city_wises = response.data;
            this.calls_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
      if (val == "visit_count") {
        this.city_wises_enrollments = [];
        this.calls_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        this.netenquiry_city_wises = [];
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/visits-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getVisitsCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getVisitsCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.visits_city_wises = response.data;
            this.visits_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getEligibilityData() {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        city: this.selected_city.toString(),
        gm: this.selected_gms.toString(),
      };
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-eligibility-summary/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallEligibilitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getEligibilityData(startDate, endDate, obj)
        .then((response) => {
          let elilibility = response.data;
          // console.log("elilibility", elilibility);
          let check_if_array = Array.isArray(elilibility);
          if (check_if_array) {
            for (let i = 0; i < response.data.length; i++) {
              const element = response.data[i];
              this.eligibility_obj = element;
              console.log("key eligibility object", this.elilibility_obj);
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getEligibilityCitySummary(val) {
      this.referrals_city_wises = [];
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.city_wises_enrollments = [];
      this.netenquiry_city_wises = [];
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        city: this.selected_city.toString(),
        gm: this.selected_gms.toString(),
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        city: this.selected_city.toString(),
        gm: this.selected_gms.toString(),
        spoc: this.selected_spoc,
      };
      if (val == "overall_eligibility_enrollments") {
        this.$vs.loading();
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/eligibility-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getEligibilityCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getEligibilityCitySummary(startDate, endDate, obj)
          .then((response) => {
            this.eligibility_city_wises = response.data;
            this.eligibility_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getReferralsData() {
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        city: this.selected_city.toString(),
        gm: this.selected_gms.toString(),
      };
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-referral-summary/?end-date=${endDate}&start-date=${startDate}`;
      // let url = `${constants.MILES_DASHBOARDS}getOverallReferralSummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.userAccessToken}`,
      //     },
      //   })
      ApiServices.getReferralsData(startDate, endDate, obj)
        .then((response) => {
          let referrals = response.data;
          // console.log("referrals", referrals);
          let check_if_array = Array.isArray(referrals);
          if (check_if_array) {
            for (let i = 0; i < referrals.length; i++) {
              const element = referrals[0];
              this.referrals_obj = element;
              // console.log("key referrals object", this.referrals_obj);
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getNetEnquirysCitySummary(val, key) {
      if (key !== null && key !== "") {
        this.calls_city_wises = [];
        this.visits_city_wises = [];
        this.eligibility_city_wises = [];
        this.referrals_city_wises = [];
        this.city_wises_enrollments = [];
        this.netenquiry_city_wises = [];
        this.$vs.loading();
        let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let obj = {
          source: val,
          city: this.selected_city.toString(),
          gm: this.selected_gms.toString(),
          spoc: this.selected_spoc,
        };
        this.date_props = {
          startDate: startDate,
          endDate: endDate,
          source: val,
          "key": key,
          city: this.selected_city.toString(),
          gm: this.selected_gms.toString(),
          spoc: this.selected_spoc,
        };
        this.$vs.loading();
        ApiServices.getNetEnquirysCitySummary(startDate, endDate, obj)
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              this.netenquiry_city_wises.push({ "city": response.data[i].city, "enquiry_count": response.data[i][key] })
            }
            this.net_enquiry_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getReferralsCitySummary(val) {
      this.calls_city_wises = [];
      this.visits_city_wises = [];
      this.eligibility_city_wises = [];
      this.referrals_city_wises = [];
      this.city_wises_enrollments = [];
      this.netenquiry_city_wises = [];
      // this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let obj = {
        spoc: this.selected_spoc,
        city: this.selected_city.toString(),
        gm: this.selected_gms.toString(),
      };
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        city: this.selected_city.toString(),
        gm: this.selected_gms.toString(),
        spoc: this.selected_spoc,
      };
      if (val == "overall_referral_enrollments") {
        this.$vs.loading();
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/referral-city-summary/?end-date=${endDate}&start-date=${startDate}`;
        // let url = `${constants.MILES_DASHBOARDS}getReferralCitySummary?start_date=${startDate}&end_date=${endDate}&spoc=${this.selected_spoc}&gm=${this.selected_gm}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.userAccessToken}`,
        //     },
        //   })
        ApiServices.getReferralsCitySummary(startDate, endDate, obj)
          .then((response) => {
            console.log("getReferralsCitySummary", response.data);
            this.referrals_city_wises = response.data;
            this.referral_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
      }
    },
    getRoadmapOverallCount(){
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      ApiServices.getRoadmapOverallCounts(startDate, endDate,this.selected_city.toString(),this.selected_spoc,this.selected_gms.toString(),)
        .then((response) => {
          let data = response.data;
          let check_if_array = Array.isArray(data);
          if (check_if_array) {
            for (let i = 0; i < data.length; i++) {
              const element = data[0];
              this.road_map_overall_counts = element;
            }
          }
          this.$vs.loading.close();
            
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    getRoadmapCitySummary(val) {
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      this.date_props = {
        startDate: startDate,
        endDate: endDate,
        gm: this.selected_gms.toString(),
        city: this.selected_city.toString(),
        spoc: this.selected_spoc,
      };
      if (val == "webinar_registrations") {
        // getRoadmapCitySummary
        ApiServices.getRoadmapCitySummary(startDate, endDate,this.selected_city.toString(),this.selected_spoc,this.selected_gms.toString())
          .then((response) => {
            console.log("getReferralsCitySummary", response.data);
            this.roadmap_city_wises = response.data;
            this.roadmap_city_summary_popup = true;
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
          });
        
      }

    },
    getOverallTrendsData() {
      console.log("gm", this.selected_gms.toString());
      console.log("cm", this.selected_spoc);
      this.$vs.loading();
      // let url = `http://milesapi.bulbot.com/api/performance-dashboard/overall-trend-summary/`;
      // if (this.selected_gm != null && this.selected_gm != "") {
      //   url += `?gm=${this.selected_gm}`;
      // } else if (this.selected_spoc != null && this.selected_spoc != "") {
      //   url += `?spoc=${this.selected_spoc}`;
      // }
      // axios
      //   .get(url, {
      //     headers: {
      //       Authorization: `Token 0d03c8c040170a8a621e1a0edbe73ad23bf158e2`,
      //     },
      //   })
      ApiServices.getOverallTrendsSummary(this.selected_gms.toString(), this.selected_spoc.toString(),this.teamm,this.selected_city.toString())
        .then((response) => {
          console.log("getOverallTrendsSummary", response.data);
          this.overall_trends = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getAllIndiaRankSummary() {
      console.log("gm--", this.selected_gms.toString());
      console.log("cm--", this.selected_spoc);
      this.$vs.loading();
      let startDate = moment(this.dateRange.startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.dateRange.endDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      if (this.selected_spoc != null && this.selected_spoc != "") {
        // let url = `http://milesapi.bulbot.com/api/performance-dashboard/spoc-all-india-rank-summary/?end-date=${endDate}&spoc=${this.selected_spoc}&start-date=${startDate}`;
        // axios
        //   .get(url, {
        //     headers: {
        //       Authorization: `Token 0d03c8c040170a8a621e1a0edbe73ad23bf158e2`,
        //     },
        //   })
        ApiServices.getAllIndiaRankSummary(
          startDate,
          endDate,
          this.selected_spoc
        )
          .then((response) => {
            console.log("spoc-all-india-rank-summary", response.data);
            let summary = response.data;
            if (summary.length > 0) {
              this.all_india_rank_summary = summary[0];
            }
            console.log(
              "spoc-all-india-rank-summary--",
              this.all_india_rank_summary
            );
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: `Error`,
              text: error.response.data.message,
              color: "danger",
            });
            this.$vs.loading.close();
          });
      }
    },
    getOverallLeads() {
      this.$vs.loading();
      ApiServices.getOverallLeads()
        .then((response) => {
          this.leads_summary = response.data

          // this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `Error`,
            text: error.response.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    refreshData() {
      this.selected_city = [];
      this.selected_gm_cc = [];
      this.selected_spoc = [];
      this.selected_gms = [];
      this.city_by_search = '';
      this.dateRange = {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ),
        endDate: new Date(),
      }
      this.getTopSpocs();
      this.getEnrollmentData();
      this.getVisitsData();
      this.getEligibilityData();
      this.getReferralsData();
      this.getNetEnquiryData("Google");
      this.getAllGmWithSpoc();
      this.getOverallTrendsData();
      this.getAllIndiaRankSummary();
      this.getSpocsBasedOnCC();
      this.gm_names = [];
      this.$emit("refresh-city-data");
    }
  },
};
</script>
<style scoped>
.heading {
  margin: 15px 0 10px 55px;
}
.title-headd{
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: 900;
  line-height: 20px;
  text-align: center;
}

.selectExample {
  width: 300px;
  margin-top: 2px;
}

.card-header {
  text-align:start;
  background: #0044ba;
  padding: 10px;
  margin: -10px;
  border-radius: 15px 15px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.selectSource {
  width: 100%;
  margin-top: 0px;
  background-color: grey;
  color: black !important;
}

.count {
  color: black;
  text-align: center;
  font-size: 30px;
  padding: 10px;
  background-color: white;
  margin: -8%;
}

.vs__dropdown-menu {
  color: black;
}
.vs__dropdown--menu {
  width: 300px;
}
.a-iconn {
  padding: 0 10px 0 10px;
  height:36px;
  outline: none;
  text-decoration: none !important;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #bfbfbf
}
.dropp-down{
  width: 100%
}
.a-iconn > .material-icons{
  margin-left: auto;
  color:#bfbfbf
}
.dropdown-cm{
  width:15%
}
@media screen and (max-width: 1445px) {
  .title-headd{
    font-size: 18px !important;
    margin-top:12px !important
  }
  .daterange-font{
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1365px) {
  .title-headd{
    font-size: 15px !important;
    margin-top:14px !important
  }
  .daterange-font{
    font-size: 11px !important;
  }
}
</style>