<template>
  <vs-popup
    fullscreen
    class="holamundo"
    :id="'utilization_untracked'"
    :title="this.iimlname + ' - ' + this.iimltab + ' - ' + this.countsleads"
    :active.sync="open"
    style="margin-right: 81%;z-index:52900"

  >
    <leadsDataTable></leadsDataTable>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-chip color="primary" style="margin-right: 2%">
          <b>{{ countsleads }}</b>
        </vs-chip>
        <vs-pagination style="margin-top: 2%" :total="totalLinks" v-model="pagination"></vs-pagination>
      </vs-col>
    </vs-row>
    <!-- <vs-pagination style="margin-top: 2%" :total="totalLinks" v-model="pagination"></vs-pagination> -->
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import leadsDataTable from "./LeadsDataTable.vue";
import moment from "moment";
import axios from "axios";
// import constants from "../../../constants.json";
export default {
  data() {
    return {
      open: false,
      calls: [],
      newcalls: [],
      title: "",
      pagination: 1,
      url: "",
      paramsObj: "",
      totalLinks: 1,
      countsleads: 0,
      iimlname: "",
      iimltab: "",
    };
  },
  components: {
    leadsDataTable,
  },
  watch: {
    pagination() {
      this.loadData();
    },
  },
  mounted() {
    // document.getElementById("utilization_untracked").children[1].style.width =
    //   "90%";
    EventBus.$on("spocDashboardAdmin-WorkLog", (payload) => {
      console.log(payload);
      this.pagination = 1;
      this.countsleads = payload[0].data.total;
      this.totalLinks = payload[0].data.last_page;
      this.iimlname = payload[3];
      this.iimltab = payload[2].category;
      if(payload[2].category === "flag") {
          this.iimltab = "Flag";
        } else if (payload[2].category === "mhps") {
            this.iimltab = "Mhps";
        } else if (payload[2].category === "mhp_called") {
            this.iimltab = "Mhp Called";
        } else if (payload[2].category === "mhp_not_called") {
            this.iimltab = "Mhp Not Called";
        } else if (payload[2].category === "waiting") {
            this.iimltab = "Waiting";
        } else if (payload[2].category === "today") {
            this.iimltab = "Today";
        } else if (payload[2].category === "delays") {
            this.iimltab = "Delays";
        } else if (payload[2].category === "ne_delays") {
            this.iimltab = "NE Delays";
        } else if (payload[2].category === "other_delays") {
            this.iimltab = "Other Delays";
        } else if (payload[2].category === "untapped") {
            this.iimltab = "Untapped";
        }

      // this.totalLinks = payload[0].data.length;
      this.url = payload[1];
      this.paramsObj = payload[2];
      //   this.calls = payload;
      this.open = true;
    });
  },
  methods: {
    loadData() {
      this.$vs.loading();
      this.paramsObj.page = this.pagination;
      axios
        .get(this.url, {
          params: this.paramsObj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          //   console.log(response);
          this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    calculatecount() {
      var unique = this.calls
        .map((name) => {
          return {
            count: 1,
            name: name.phone_number,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let clonearr = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if (unique[key] === 1) {
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                clonearr.push(call);
                clonearr.push("br");
              }
            });
          } else {
            let tempArr = [];
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                tempArr.push(call);
              }
            });
            tempArr.forEach((temp, index) => {
              if (index > 0) {
                temp.count = "";
                tempArr[0].call_duration =
                  tempArr[0].call_duration + temp.call_duration;
                temp.call_duration = "";
                temp.level = "";
              } else {
                temp.count = unique[key];
              }
              clonearr.push(temp);
            });
            clonearr.push("br");
          }
        }
      }
      this.newcalls = clonearr;
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      var date = new Date(value * 1000);
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var formattedTime =
        hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return `${dateString} - ${formattedTime}`;
      }
    },
    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var formatteddate =
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2);

      return `${formatteddate}`;
    },
  },
};
</script>

<style>
/* .vs-popup--title {
    margin-right: 71%;
} */
</style>
