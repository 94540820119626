<template>
  <vs-popup class="holamundo" :id="'utilization_untracked'" :title="title" :active.sync="open">
    <!-- <leadsDataTable></leadsDataTable> -->
    <vs-table v-if="open"  :data="tableData.data">
      <template slot="thead">
        <vs-th>Enquiry Date</vs-th>
        <vs-th>Name</vs-th>
        <vs-th v-if="user_id == 132 || user_id == 122 || user_id == 937">Mobile</vs-th>
        <!-- <vs-th>Type</vs-th> -->
        <vs-th>Course</vs-th>
        <vs-th>Source</vs-th>
        <vs-th>City</vs-th>
        <vs-th>Campaign Id</vs-th>
        <vs-th>Uploaded Date</vs-th>
        <!-- <vs-th>View Info</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>{{ tr.enquiry_date }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.name }}</div>
            </vs-td>
            <vs-td v-if="user_id == 132 || user_id == 122 || user_id == 937">
              <div>{{ tr.mobile }}</div>
            </vs-td>
            <!-- <vs-td>
              <div>{{ tr.net_enquiry_type }}</div>
            </vs-td>-->
            <vs-td>
              <div>{{ tr.course }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.source }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.allocated_city }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.campaign_id }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.created_at }}</div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-chip color="primary" style="margin-right: 2%">
          <b>{{ countsleads }}</b>
        </vs-chip>
        <vs-pagination style="margin-top: 2%" :total="totalLinks" v-model="pagination"></vs-pagination>
      </vs-col>
    </vs-row>
    
    <!-- <vs-pagination
      style="margin-top: 2%"
      :total="totalLinks"
      v-model="pagination"
    ></vs-pagination>-->
  </vs-popup>
</template>

<script>
import EventBus from "../eventbus.js";
import leadsDataTable from "./LeadsDataTable.vue";
import moment from "moment";
import axios from "axios";
// import constants from "../../../constants.json";
export default {
  data() {
    return {
      user_id: null,
      open: false,
      calls: [],
      newcalls: [],
      title: "",
      pagination: 1,
      url: "",
      paramsObj: "",
      totalLinks: 1,
      tableData: [],
      countsleads: 0,
      tablelinks: 0,
      currentTablePage: 1,
    };
  },
  components: {
    leadsDataTable,
  },
  watch: {
    pagination() {
      // console.log("value", value)
      // this.analyzePagination(value);
      this.loadData();
    }
    // pagination() {
    //   this.loadData();
    // },
  },
  mounted() {
    this.user_id = localStorage.getItem("spoc_id");
    document.getElementById("utilization_untracked").children[1].style.width =
      "90%";
    EventBus.$on("spocDashboardAdminCM-NetEnquiries", (payload, url, obj, category, spocName) => {
      console.log("payload with name", payload );
      this.tableData = payload;
      this.countsleads = this.tableData.total;
      this.pagination = 1;
      this.totalLinks = this.tableData.last_page;
      console.log(url)
      // this.analyzePagination(1);
      if(category == 'net_website_google'){
        this.title = `NE Bucket 1 - ${spocName} - ${payload.total}`;
      }else if(category == 'net_other_sources'){
        this.title = `NE Bucket 2 - ${spocName} - ${payload.total}`;
      }
      // this.title = `${payload.total} - Net Enquiries - ${spocName}`;
      //   this.pagination = 1;
      //   this.totalLinks = payload[0].data.last_page;
        this.url = url;
        
        this.paramsObj = obj;
      //   this.calls = payload;
      this.open = true;
    });
  },
  methods: {
    openPopupEmit(payload) {
      this.openBigPopup(payload.mwb_id);
    },
    loadData() {
      this.$vs.loading();
      this.paramsObj.page = this.pagination;
      axios
        .get(this.url, {
          params: this.paramsObj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          //   console.log(response);
          // this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          this.$vs.loading.close();
          this.tableData = response.data

        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    calculatecount() {
      var unique = this.calls
        .map((name) => {
          return {
            count: 1,
            name: name.phone_number,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let clonearr = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if (unique[key] === 1) {
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                clonearr.push(call);
                clonearr.push("br");
              }
            });
          } else {
            let tempArr = [];
            this.calls.forEach((call) => {
              if (call.phone_number === key) {
                tempArr.push(call);
              }
            });
            tempArr.forEach((temp, index) => {
              if (index > 0) {
                temp.count = "";
                tempArr[0].call_duration =
                  tempArr[0].call_duration + temp.call_duration;
                temp.call_duration = "";
                temp.level = "";
              } else {
                temp.count = unique[key];
              }
              clonearr.push(temp);
            });
            clonearr.push("br");
          }
        }
      }
      this.newcalls = clonearr;
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      var date = new Date(value * 1000);
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var formattedTime = hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return `${dateString} - ${formattedTime}`;
      }
    },
    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var formatteddate =
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2);

      return `${formatteddate}`;
    },
  },
};
</script>

<style></style>
